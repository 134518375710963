.Header_main {
    width: 100vw;
    height: 79vh;
    margin-bottom: 100px;
}

.header {
    background-image: url("../Images/Banner.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column; /* Ajusta la dirección del flex a vertical */
    align-items: center; /* Centra los elementos horizontalmente */
    justify-content: center; /* Centra los elementos verticalmente */
}

.Header_main .header h1 {
    background-color: rgba(0, 0, 0, 0.286); /* Fondo negro con opacidad */
    padding: 10px; /* Espacio alrededor del texto */
    border-radius: 5px; /* Bordes redondeados para el fondo */
    filter: brightness(100);
    font-size: 45px;
    width: 70%;
    color: rgb(177, 35, 35);
    text-align: center;
    z-index: 20;
    margin: 0px 0; /* Agrega un pequeño margen para separar las frases */
}

.header .expertHands { /* Estilos para la frase "¡Te encuentras en Manos expertas!" */
    margin-top: 20px; /* Ajusta según necesites el espacio entre las frases */
    font-size: 35px; /* Ajusta el tamaño de la fuente según sea necesario */
    color: #fff; /* Ajusta el color de la fuente si es necesario */
}

.Header_main .subHeader {
    width: 100%;
    height: 80px;
    background-color: #1F2426;
    color: #ffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Header_main .subHeader .headerButton {
    background-color: #F9D93A;
    color: #000;
    height: 30px;
    width: 200px;
    font-weight: bold;
    margin-left: 20px;
}

.Footer_main {
    background-color: #1F2426;
    width: 100vw;
    height: 250px;
    max-width: 100vw;

}

.Footer_main .row {
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    flex-direction: row;
    height: 100%;

}

.Footer_main .end {
    color: #fff;
    display: flex;
    height: 50px;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-top: 0.5px rgb(187, 187, 187) solid;
    padding: 20px;
    background-color: #1F2426;

}

.Footer_main .row .left {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.Footer_main .row .footer {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.Footer_main .row .footer .list1 {
    width: 40%;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    color: #fff;
}

.Footer_main .row .footer .list1 .button {

    color: #fff !important;
}

.Footer_main .row .css-1j61pew-MuiButtonBase-root-MuiButton-root {
    color: #fff !important;

}

.Footer_main .row .footer .list1 strong {
    margin-bottom: 20px;

}

.Footer_main .row .footer .list2 {
    width: 40%;
    display: flex;
    flex-direction: column;
    color: #fff;
    padding-top: 20px;

}

.Footer_main .row .footer .list2 strong {
    margin-bottom: 30px;
    color: #F9D93A
}

.Footer_main .row .footer .list2 span {
    margin-bottom: 10px;
}


@media only screen and (max-width: 768px) {


    .Header_main .header h1 {

        filter: brightness(100);
        font-size: 25px;
        width: 90%;
        color: #ffff;
        text-align: center;
        z-index: 20;
    }

    .Header_main .subHeader {
        width: 100%;
        background-color: #1F2426;
        color: #ffff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
    }

    .Header_main .subHeader h2 {
        font-size: 15px;
    }

    .Header_main .subHeader .headerButton {
        background-color: #F9D93A;
        color: #000;
        font-weight: bold;
        margin-left: 10px;
        font-size: 10px;
    }

    .Footer_main {
        width: 100vw;
        height: auto;


    }

    .Footer_main .row {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .Footer_main .row .left {
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .Footer_main .row .left img {

        height: 80px;
    }

    .Footer_main .row .footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;

    }


    .Footer_main .row .footer .list1 {
        width: 50%;
        padding-top: 20px;
        display: flex;
        flex-direction: column;
        color: #fff;
        font-size: 12px;

    }

    .Footer_main .row .footer .list1 .button {

        font-size: 12px;

    }




    .Footer_main .row .footer .list2 {
        width: 50%;
        display: flex;
        flex-direction: column;
        color: #fff;
        padding-top: 20px;
        font-size: 12px;

    }



}
.WorkUs_main {
    padding: 50px 100px 50px 100px;
    width: 100vw;
    text-align: justify;
}

.WorkUs_main h1 {
    color: #1F2426;
    margin-bottom: 50px;
    margin-left: 30px;
}

.WorkUs_main .form-container {
    display: flex;
    flex-direction: row;
    justify-content: center;


}
.WorkUs_main .form-container .row  {

    display: flex;
    width: auto;
    height: 100%;
    width: 90%;
    flex-direction: row;
    border-radius: 50px 50px 0px 50px;

    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}


.WorkUs_main .form-container .row  .column_img{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url("../Images/work\ whith\ us.png");
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover; 
    border-radius: 0px 50px 0px 0px;


}

.WorkUs_main .form-container .row  .column_form{
    width: 60%;
}

.WorkUs_main .form-container .row  .column_form .form {
    padding: 25px;
   
    background-color: rgb(255, 255, 255);
    border-radius: 50px 0px 0px 50px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}



.WorkUs_main .form-container .row  .column_form .form  .sendButton{

    width: 50%;
    margin-bottom: 20px;
}

.WorkUs_main .form-container .row  .column_form .form span {

    color: #1F2426;
    font-size: 18px;
}

.WorkUs_main .form-container  .contact {
    background-color: #cecdcd;
    width: 70px;
    height: 100px;
    border-radius: 0px 50px 50px 0px;
    justify-self: end;
    align-self: flex-end;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.2375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding: 11.5px 12px !important;
    padding-right: 0;
}

.css-kmuk5e {
     padding-top: 0px; 
     margin-bottom: 0px !important; 
}



@media only screen and (max-width: 768px) {

    .WorkUs_main .form-container {
        padding: 10px;
        margin-bottom: 30px;

    }

    .WorkUs_main .form-container .row  .column_form{
        width: 100%;
    }

    .WorkUs_main .form-container .row  .column_form .form {
        padding: 20px;
        background-color: rgb(255, 255, 255);
        border-radius: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        
    }

    .WorkUs_main .form-container .row  {

        display: flex;
        width: auto;
        height: 100%;
        width: 100%;
        flex-direction: column;
        border-radius: 50px 50px 50px 50px;
    
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    
    }

    .WorkUs_main .form-container .row  .column_img{
        display: none;

    }

    
    .WorkUs_main  {
        padding: 10px ;
        width: 100vw;
    }

    .WorkUs_main h1 {
        margin-bottom: 10px;
        text-align: center;
    }

    
    .WorkUs_main .form-container  .contact {
      display: none;
    
    }

   

}
.section1 {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 40px;
    margin-bottom: 30px;

}

.section1 h1 {
    text-align: left;
    padding-left: 80px;
    padding-bottom: 50px;
    color: #1F2426;
}

.section1 .grid {
    margin-left: 20px;
    max-width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding-bottom: 20px;

}



.item_card {
    display: inline-block;
    position: relative;
    justify-content: center;
    align-items: center;
    border-radius: 30px;


}

.item_text {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    padding: 10px;
    margin: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    font-weight: bold;
    font-size: 25px;
}

.desvanecer {
    height: 300px;
    border-radius: 30px;

}

.desvanecer:hover {
    opacity: 0.07;
    -webkit-transition: opacity 500ms;
    -moz-transition: opacity 500ms;
    -o-transition: opacity 500ms;
    -ms-transition: opacity 500ms;
    transition: opacity 500ms;
}

.grid .text {
    width: 500px;
    text-align: justify;
}

.section2 {
    width: 100vw;
    height: auto;
    color: #fff;
    background-color: #1F2426;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
}


.section2 h1 {
    text-align: left;
    padding-left: 80px;
    padding-bottom: 30px;


}

.section2 .img_container {

    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 10px;
}

.section2 .img_container .item_img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.section2 .img_container .item_img span {
    color: #F9D93A;
    font-weight: bolder;
    margin-top: 20px;
    size: 30px;
}

.section3 {
    width: 100vw;
    background-color: #F2F2F2;
}

.section3 .row {

    display: flex;
    width: auto;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.section3 .row .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
    margin-right: -190px;
    z-index: 20;
    padding: 30px;
    width: 400px;
    border-radius: 50px;
    background-color: #ffffff;
}

.section3 .row .left h2 {
    color: #1F2426;
    margin-bottom: 10px;
}

.section3 .row .left .list {
    display: flex;
    flex-direction: flex;
    flex-wrap: wrap;
}



.section3 .row .map {
    margin-left: -20px;
    width: 60%;
}

.section3 .subHeader {
    width: 100%;
    background-color: #1F2426;
    color: #ffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.section3 .subHeader a {
    font-size: 15px;
    color: #ffff;
    font-weight: bold;

}

.section3 .subHeader .headerButton {
    background-color: #F9D93A;
    color: #000;
    font-weight: bold;
    margin-left: 10px;
    font-size: 10px;
}



.section4 {
    padding-right: 100px;
    padding-top: 50px;
    padding: 50px 50px 50px 100px;
    width: 100vw;
    text-align: justify;
    background-color: #fff;
}

.section4 h1 {
    color: #1F2426;


}

.section4 .carousel_container {
    margin-top: 50px;
}



@media only screen and (max-width: 768px) {

    .section1 h1 {
        text-align: center;
        padding-left: 0px;

    }


    .section1 .grid {
        margin-left: 0px;
        max-width: 100vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        overflow-x: scroll;
        padding-bottom: 20px;

    }



    .item_card {
        display: inline-block;
        position: relative;
        justify-content: center;
        align-items: center;
        border-radius: 30px;


    }

    .item_card>div {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        padding: 10px;
        margin: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
    }

    .desvanecer {
        width: 95%;
        height: auto;
        border-radius: 30px;

    }


    .section3 {
        width: 100vw;
        margin-top: 50px;
    }

    .section3 .row {
        display: flex;
        width: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .section3 .row .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: justify;
        margin-right: 0px;
        z-index: 20;
        padding: 30px;
        width: 84%;
        border-radius: 50px;
        background-color: #e4e4e4;
    }

    .section3 .row .left h2 {
        color: #1F2426;
        margin-bottom: 10px;
        text-align: left;
    }

    .section3 .row .map {
        margin-top: -150px;
        width: 100%;
    }


    .section4 {
        padding-left: 0px;
        padding-top: 50px;
    }

    .section4 h1 {
        color: #1F2426;
        text-align: center;

    }


    .section4 .carousel_container img {
        width: 80%;
        height: auto;
        padding-left: 50px;

    }





}
.App {
  text-align: center;
}

.navBar {
  background-color: #ffffff;
  color: black;
  height: 110px;
  display: flex;
  padding-left: 15px;
  justify-content: space-between;
  align-items: center;
}

.navBar .navbar-logo{
 height: 80px;

}

.navBar .navButton{
  color: #1F2426;
  font-size: 15px;
  height: 100%;

}

.navBar .navButton:hover{
  background-color: #d4d4d4;
  font-size: 15px;
}

.main .float_buttom {

  transition: all .2s ease-in-out;

}

.main .float_buttom:hover {

  transform: scale(1.1);
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@media only screen and (max-width: 768px) {

  .navBar .navbar-logo{
    height: 60px;
   
   }
}
.About_main {
    padding: 50px 100px 50px 100px;
    width: 100vw;

    text-align: justify;
    background-image: url("../Images/about.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.About_main h1 {
    color: #fff;
    margin-bottom: 50px;
    margin-left: 30px;
}

.About_main h2 {
    color: #fff;
    margin-bottom: 50px;
    margin-left: 30px;
    margin-top: 20px;
}


.About_main .text_container {
    color: #ffff;
    padding: 30px 20px 20px 30px;
    background-color: rgba(0, 0, 0, 0.588); /* Fondo negro con opacidad */
    border-radius: 5px; /* Bordes redondeados para el fondo */   
    text-align: justify;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    margin-bottom: 20px;
}

.About_main .text_container h2 {
    color: #ffff;
    margin-bottom: 5px;
    margin-left: 0px;
    margin-top: 0px;

}


@media only screen and (max-width: 768px) {


    .About_main {
        padding: 10px ;
        width: 100vw;
    
    
    }
}
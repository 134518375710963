.Map {
    font-family: sans-serif;
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  
  #chart-container {
    position: relative;
    width: 100%;
    height: auto;    
    overflow: hidden;
  }
  